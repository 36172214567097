import { useRouter } from 'next/router'
import { useEffect } from 'react'

// TODO 추후 로그인 온보딩 페이지가 들어올 수 있어 root 도메인은 사용하지 않습니다.
export const Main = () => {
  const router = useRouter()

  useEffect(() => {
    router.push('/auth/sign-in')
  }, [router])

  return null
}
